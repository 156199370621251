import axios from "axios";

// Base URLs
const BASE_URL = "https://ms-euda-users-data.euda.apps.devocp.safaricom.net";
// const BASE_URL = "http://localhost:8081";

//Get user role

export async function getRole(username) {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/chatbot-user-analytics/users/${username}`
    );

    return response;
  } catch (error) {
    console.error(
      "Error updating role:",
      error.response ? error.response.data : error.message
    );
    return 400;
  }
}

//Update user role

export async function updateRole(id, role) {
  try {
    const response = await axios.patch(
      `${BASE_URL}/euda-api/v1/chatbot-user-analytics/${id}/role?role=${role}`
    );

    return response;
  } catch (error) {
    console.error(
      "Error updating role:",
      error.response ? error.response.data : error.message
    );
    return 400;
  }
}

//Unlock account

export async function unlockUserAccount(username) {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/unlock-user?username=${username}`
    );

    console.log(
      "Account unlocked successfully:",
      response.data.header.responseCode
    );
    return response && response.data.header.responseCode ? response.data.header.responseCode : 400;
  } catch (error) {
    console.error(
      "Error unlocking account:",
      error.response ? error.response.data : error.message
    );
    return 400;
  }
}

//Get AD user information

export const getAdCredentials = async (username) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/user-info?username=${username}`
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting user information");
    }

    // Log the actual object in a readable format
    // console.log("User information :: ", response.data);


    return response.data;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Get total users

export const getUserPercentage = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/chatbot-user-analytics/user-percentage-difference`
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting user percentage");
    }

    return response.data;

  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Get users record

export const getUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/euda-api/v1/chatbot-user-analytics/all-users`);

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting users");
    }

    return response.data;

  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

export const createCategory = async (username, category) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/euda-api/v1/categories?username=${username}`,
      {
        category_name: category,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Category res :: ", response.data)

    if (response.status !== 200) {
      throw new Error("Problem occurred creating a category");
    }

    return response.data;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

export const getCategories = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/categories`
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting categories");
    }

    return response.data;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Get total users

export const getUsersCount = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/euda-api/v1/chatbot-user-analytics/total-users`);

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting total users");
    }

    return response.data;

  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Get total visits

export const getTotalVisits = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/chatbot-user-analytics/total-visits`
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting visits");
    }

    console.log("Total visits :: ", response);

    return response.data;

  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Get visits percentage

export const getVisitsPercentage = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/chatbot-user-analytics/visit-percentage-difference`
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting visits percentage");
    }

    return response.data;

  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Create User

export const createUser = async (username, platform) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/euda-api/v1/chatbot-user-analytics`,
      {
        username: username,
        platform: platform,
      }
    );
    console.log(response);

    if (response.status !== 200) {
      throw new Error("Problem occurred while creating a user");
    }

  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

// Get user details

export const getUserDetails = async (username) => {
  try {
    const response = await axios.post(
      `${DXL_BASE_URL}/api/v1/ad/user`,
      {
        username: username,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred. Retry");
    }

    if (response.data.body.email.toLowerCase() === username + "@safaricom.co.ke".toLowerCase()) {
    return response.data.body;
    }else{
      throw new Error("Username not found");
    }
  } catch (error) {
    console.error("Error getting username :: ", error);
    throw error;
  }
};

// Generate and send OTP

export async function generateAndSendOTP(phoneNumber) {
  try {
      const response = await axios.post(`${BASE_URL}/euda-api/v1/sendOTP?phoneNumber=${phoneNumber}`);

      if (response.status !== 200) {
        throw new Error("Problem while generating and sending OTP");
      }

      return response.data;
  } catch (error) {
      console.error('Error generating OTP :: ', error);
      throw error;
  }
}

// Validate OTP

export async function validateOTP(phoneNumber, otp) {
  try {
      const response = await axios.post(`${BASE_URL}/euda-api/v1/verifyOTP?phoneNumber=${phoneNumber}&enteredOTP=${otp}`);

      if(response.status !== 200) {
        throw new Error("Problem validating OTP");
      }

      return response.data;
  } catch (error) {
      console.error('Error validating OTP :: ', error);
      throw error;
  }
}

// Gemini api

export async function getExternalModel(text) {
  try {
    const response = await axios.post(
      `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyA9dd3AEBr1khgrjbI-GwSCV6vuqNKYcOw`,
      {
        contents: [
          {
            parts: [
              {
                text,
              },
            ],
          },
        ],
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem getting external data");
    }

    return response.data;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
}

//Get Faqs

export async function getFaqs() {
  try {
    const response = await axios.get(`${BASE_URL}/euda-api/v1/faqs`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error("Problem getting faqs");
    }

    return response.data;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
}

//model endpoint

export async function getModel(topic) {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/faq?topic=${topic}`
    );

    if (response.status !== 200) {
      throw new Error("Problem getting internal data");
    }

    return response.data.answer;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
}

// Fetch FAQ request

export const getFaq = async (faq) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/euda-api/v1/chatbot/query`, {
          query: faq
        }
      );
      if (response.status !== 200) {
        throw new Error(`Failed to fetch FAQ`);
      }

      return response.data;
      
    } catch (error) {
      throw error;
    }
};

// Fetch all device details

export const getAllDevices = async (username) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/euda-api/v1/device-allocation?username=${username}`
      );
      if (response.status !== 200) {
        throw new Error(`Failed to fetch devices.`);
      }

      return response.data;
      
    } catch (error) {
      console.error("Error fetching devices :: ", error);
      throw error;
    }
};

export const getAllFeedback = async (username) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/euda-api/v1/feedbacks`
    );
    if (response.status !== 200) {
      throw new Error(`Failed to fetch feedback.`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching feedback :: ", error);
    throw error;
  }
};

export const postFeedback = async (feedback) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/euda-api/v1/feedback`, {
        text: feedback.text,
        fileUrl: feedback.file
      }
    );
    if (response.status !== 201) {
      throw new Error(`Failed to post feedback.`);
    }

    return response.data;
  } catch (error) {
    console.error("Error posting feedback :: ", error);
    throw error;
  }
};

export const postFaq = async (faq) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/euda-api/v1/admin-faqs?username=${faq.adUsername}`,
      {
        topic: faq.title,
        attachment: faq.category,
        answer: faq.description
      }
    );
    if (response.status !== 201) {
      throw new Error(`Failed to post faq.`);
    }

    return response.data;
  } catch (error) {
    console.error("Error posting faq :: ", error);
    throw error;
  }
};